<!-- @format -->

<template>
  <div>
    <MobileHeader />
    <MobileTop />
    <BaseKinostart :kinostart1="kinostart1" :kinostart2="kinostart2" />
    <div class="packshot">
      <a :href="this.$config.dvd_link" target="_blank">
        <img src="~@/assets/images/mobile_packshot.png" alt="Hier kaufen *" />
      </a>
    </div>
    <div class="preise">
      <img src="@/assets/images/mobile_von.png" alt="" />
    </div>

    <MobileTrailerSizzle />

    <!-- Inhalt -->
    <div id="mob-inhalt">
      <MobileExpandableCard
        class="mob-inhalt"
        image="card_inhalt.jpg"
        btnTopAlign="left"
        btnTopColor="#935978"
        btnTopBg="white"
        btnBottomColor="#935978"
        btnBottomBg="white"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Inhalt </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.inhalt.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileExpandableCard>
    </div>

    <!-- Fotos -->
    <div id="mob-fotos" @click="foto = 0">
      <MobileCard
        class="mob-fotos"
        image="card_fotos.jpg"
        btnTopAlign="left"
        btnTopColor="#935978"
        btnTopBg="white"
        btnBottomColor="#935978"
        btnBottomBg="white"
        cardBorderRadius="0px"
        openButton="Zur Bildergalerie"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Fotos </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Die Bilder zum Dokumentarfilm <span>BODY OF TRUTH</span> über vier
          starke Frauen gibt es hier ...
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileCard>
    </div>

    <!-- Künstlerinnen -->
    <div id="mob-artists">
      <MobileExpandableCard
        class="mob-artists"
        image="card_kuenstler.jpg"
        btnTopAlign="left"
        btnTopColor="#935978"
        btnTopBg="white"
        btnBottomColor="#935978"
        btnBottomBg="white"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Künstlerinnen </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Marina Abramović, eine der größten Performance- Künstlerinnen unserer
          Zeit, setzt sich immer wieder mit ...
        </template>
        <!-- Hidden Maincontent -->
        <artists />
      </MobileExpandableCard>
    </div>

    <!-- Regisseurin -->
    <div id="mob-regie">
      <MobileExpandableCard
        class="mob-regie"
        image="card_regie.jpg"
        btnTopAlign="left"
        btnTopColor="#935978"
        btnTopBg="white"
        btnBottomColor="#935978"
        btnBottomBg="white"
        cardBorderRadius="0px"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Regisseurin </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Die in München geborene Autorin und Regisseurin von Dokumentarfilmen,
          Evelyn Schels, entdeckte ihre Liebe ...
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.regie.teaser"></div>
      </MobileExpandableCard>
    </div>

    <!-- Interview -->
    <div id="mob-interview">
      <MobileExpandableCard
        class="mob-interview"
        image="card_interview.jpg"
        btnTopAlign="left"
        btnTopColor="#935978"
        btnTopBg="white"
        btnBottomColor="#935978"
        btnBottomBg="white"
        cardBorderRadius="0px"
      >
        <!-- H1 Headline -->
        <template v-slot:headline>INTERVIEW</template>
        <!-- Teaser -->
        <template v-slot:teaser>
          Spannende Fragen an Regisseurin Evelyn Schels über die Entstehung des
          Films <span>BODY OF TRUTH</span> ...
        </template>
        <!-- Hidden Maincontent -->
        <div style="margin-bottom: 20px">
          <h1>FRAGEN AN EVELYN SCHELS</h1>
        </div>
        <div v-html="$texte.regie.more"></div>
      </MobileExpandableCard>
    </div>

    <BaseFooter
      :kinostart1="kinostart1"
      :kinostart2="kinostart2"
      :billing="true"
      :youtubenote="false"
    />
    <BaseModalGallery :foto="foto" />
  </div>
</template>

<script>
  import moment from 'moment'

  import texte from '@/views/texte/all.json'
  import Artists from '@/views/ArtistsNew.vue'

  export default {
    components: {
      Artists,
    },
    data() {
      return {
        foto: null,
        images: [],
        texte: texte,
      }
    },
    computed: {
      kinostart1() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      kinostart2() {
        return moment() < moment(this.$config.release_line_2.date)
          ? this.$config.release_line_2.line_before
          : this.$config.release_line_2.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
    },
    methods: {
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>

<style lang="scss">
  body {
    background-color: $primary;
  }
  .packshot {
    display: flex;
    padding: 5% 0;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
    }
    img {
      width: 80%;
      max-width: 344px;
    }
  }
</style>
